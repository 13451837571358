import { Component, OnDestroy } from '@angular/core';
import DateBox from 'devextreme/ui/date_box';
import TextBox from 'devextreme/ui/text_box';
import SelectBox from 'devextreme/ui/select_box';
import NumberBox from 'devextreme/ui/number_box';
import DataGrid from 'devextreme/ui/data_grid';
import Button from 'devextreme/ui/button';
import Popup from 'devextreme/ui/popup';
import config from 'devextreme/core/config';

import { LanguageService } from './share/services/language.service';
import { Subscription, withLatestFrom } from 'rxjs';
import { ConfigService } from './share/services/config.service';
import { ApiService } from './share/services/api.service';
import { RouteKeys } from './share/helper/route-keys.helper';
import dxToast from 'devextreme/ui/toast';
import { SysService } from './share/services/sys.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnDestroy {
  // selectBoxLanguageObservable: Subscription;
  isInit: boolean = false;

  isMobile: boolean;

  constructor(
    private languageService: LanguageService,
    private configService: ConfigService,
    private apiService: ApiService,
    private sysService: SysService
  ) {
    this.getVersion();
    this.configService.init().subscribe((isInit) => {
      this.isInit = isInit;
    });

    let _sysInfo: any = this.sysService.getSystemInformations();
    this.isMobile = _sysInfo.isMobile;

    this.languageService.init();
    this.setGlobalConfig();
  }

  getVersion() {
    this.apiService.post<any>(RouteKeys.Config.get_version, null).subscribe((response) => {
      console.info('CZ-Kita-Bürger:', response.version);
    });
  }

  ngOnDestroy(): void {
    // if (this.selectBoxLanguageObservable) {
    //   this.selectBoxLanguageObservable.unsubscribe();
    // }
  }

  setGlobalConfig() {
    config({
      editorStylingMode: 'outlined', // 'filled' | 'outlined' | 'underlined'
      defaultCurrency: 'EUR',
      forceIsoDateParsing: true,
      floatingActionButtonConfig: {
        position: { at: 'left bottom', my: 'left bottom', offset: '0 -40' },
      },
    });

    NumberBox.defaultOptions({
      options: {
        showClearButton: true,
        min: 1,
        max: 31999,
        showSpinButtons: this.isMobile ? false : true,
      } as any,
    });

    SelectBox.defaultOptions({
      options: {
        showClearButton: true,
      },
    });

    TextBox.defaultOptions({
      options: {
        showClearButton: true,
        maxLength: 3999,
        labelLocation: 'top',
      } as any,
    });

    DateBox.defaultOptions({
      options: {
        //showClearButton: true,
        displayFormat: 'dd.MM.yyyy',
        type: 'date',
        pickerType: 'calendar',
        min: '1900/1/1',
        max: '2099/12/31',
        useMaskBehavior: true,
        dateSerializationFormat: 'yyyy-MM-ddTHH:mm:ss',
        acceptCustomValue: this.isMobile ? false : true,
      } as any,
    });

    DataGrid.defaultOptions({
      options: {
        columnAutoWidth: true,
        showColumnLines: true,
        showRowLines: false,
        showBorders: false,
        rowAlternationEnabled: true,
        allowColumnReordering: true,
        allowColumnResizing: true,
        columnResizingMode: 'widget',

        scrolling: {
          useNative: false,
          mode: 'virtual',
          showScrollbar: 'always',
        },
        editing: {
          popup: {
            onContentReady: (e) => {
              const toolbarItems = e.component.option('toolbarItems');
              toolbarItems[0].options.type = 'default';
              toolbarItems[0].options.icon = 'save';
              toolbarItems[1].options.type = 'default';
              toolbarItems[1].options.icon = 'remove';
              e.component.option('toolbarItems', toolbarItems);
            },
          },
        },
      } as any,
    });

    Button.defaultOptions({
      options: {
        focusStateEnabled: false,
        hoverStateEnabled: false,
      } as any,
    });

    Popup.defaultOptions({
      options: {
        shadingColor: 'rgba(200, 200, 200, 0.75)',
      } as any,
    });

    dxToast.defaultOptions({
      options: {
        width: '320px',
        displayTime: '3000',
        position: {
          at: 'bottom center',
          my: 'bottom center',
          offset: '0 -40',
          //of: '#app-table-container'
        },
      } as any,
    });
  }
}
